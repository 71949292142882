import React, { Fragment, useEffect, useMemo, useState } from "react"
import { Select, useForm } from "@app/components/Form"
import { Dialog } from "@app/components/Modal"
import DateFnsUtils from "@date-io/date-fns"
import { IconButton, makeStyles } from "@material-ui/core"
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers"
import { Form, Col, Button } from "react-bootstrap"
import AccessTimeIcon from "@material-ui/icons/AccessTime"
import ClearIcon from "@material-ui/icons/Clear"
import { SplitShift } from "@app/containers/spa/WhiteboardCalendar/data"
import ProvidersCustomSelectComponent from "../ProviderCustomSelect/ProvidersCustomSelect"
import { formatDate, parseDate } from "@app/utils"
import { useSelector, useDispatch } from "@app/models"
import { validateSplitShifts } from "@app/services/splitShiftValidation"
import { SplitShiftForm } from "@app/containers/spa/WhiteboardCalendar/component/JobsTableModal/hooks/jobsTableUseForm"
import { useJobTimes } from "../CalendarGridView/hooks/useJobTime"

import css from "./SplitShiftEditor.module.scss"
import cx from "classnames"

interface SplitShiftInfo extends SplitShift {
  disabled: boolean
}

type PropsType = {
  jobid: number
  edate: string
  splitShifts: SplitShift[]
  assignments: AssignmentBaseType[]
  onChange: (value: SplitShiftForm) => void
  hideSplits: () => void
}

type FormFieldsType = {
  providerids: { providerid: number }[]
}

export default ({
  splitShifts,
  assignments,
  jobid,
  edate,
  onChange,
  hideSplits,
}: PropsType) => {
  const { jobs } = useSelector((state) => state.groupData)
  const [newSplitShifts, setNewSplitShifts] = useState<SplitShift[]>([])
  const { jobStartTime, jobEndTime } = useJobTimes(jobid, edate)

  const dispatch = useDispatch()

  const assignment = assignments[0]
  const job = useMemo(() => {
    if (assignment) {
      return assignment.job
    } else {
      return jobs.find((job) => job.jobid === jobid)
    }
  }, [assignment, jobs, jobid])

  if (!job) return null

  useEffect(() => {
    let splitShiftsCopy = [] as SplitShift[]

    if (splitShifts.length || assignment) {
      if (!splitShifts.length) {
        splitShiftsCopy.push({
          providerid: assignment.providerid,
          tally_credit: 1,
          starttime: jobStartTime,
          endtime: jobEndTime,
          edate: assignment.edate,
        })
      } else {
        splitShiftsCopy = [...splitShifts].sort((aSplit, bSplit) =>
          `${aSplit.edate}${aSplit.starttime}`.localeCompare(
            `${bSplit.edate}${bSplit.starttime}`
          )
        )
      }
      splitShiftsCopy = splitShiftsCopy.map((splitShift) => {
        return {
          ...splitShift,
          disabled: false,
        }
      })
    } else {
      splitShiftsCopy.push({
        starttime: jobStartTime,
        endtime: jobEndTime,
        disabled: false,
      } as SplitShiftInfo)
    }

    while (splitShiftsCopy.length < 4) {
      splitShiftsCopy.push({ disabled: true } as SplitShiftInfo)
    }
    setNewSplitShifts(splitShiftsCopy as SplitShiftInfo[])
  }, [job, splitShifts])

  const clearSplitShiftRow = (index: number) => {
    setNewSplitShifts([
      ...newSplitShifts.slice(0, index),
      { disabled: true } as SplitShiftInfo,
      ...newSplitShifts.slice(index + 1),
    ])
  }

  const changeSplitShiftTimes = (
    index: number,
    starttime: string,
    endtime: string
  ) => {
    const splitShiftsCopy = [...newSplitShifts]

    splitShiftsCopy[index].starttime = starttime
    splitShiftsCopy[index].endtime = endtime

    if (
      index < 4 &&
      splitShiftsCopy[index + 1] &&
      job &&
      endtime !== jobEndTime
    ) {
      splitShiftsCopy[index + 1].starttime = endtime
      splitShiftsCopy[index + 1].endtime ||= jobEndTime
      splitShiftsCopy[index + 1].disabled = false
    }

    if (index > 0 && splitShiftsCopy[index - 1]) {
      splitShiftsCopy[index - 1].endtime = starttime
    }

    setNewSplitShifts(splitShiftsCopy)
  }

  const changeSplitShiftProvider = (index: number, providerid: number) => {
    const splitShiftsCopy = [...newSplitShifts]

    splitShiftsCopy[index].providerid = providerid

    setNewSplitShifts(splitShiftsCopy)
  }

  const changeSplitShiftTallyCredit = (index: number, tallyCredit: number) => {
    const splitShiftsCopy = [...newSplitShifts]

    splitShiftsCopy[index].tally_credit = tallyCredit

    setNewSplitShifts(splitShiftsCopy)
  }

  const formatTime = (date: any) => {
    if (date) {
      const time = formatDate(date, (f) => f.timeOnly)
      return time.substring(0, 6) + "00"
    }
    return ""
  }

  const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root": {
        width: 120,
        height: 24,
        borderRadius: 4,
        position: "relative",
        backgroundColor: "#fff",
        border: "1px solid #ced4da",
        paddingRight: 0,
      },
      "&:focus": {
        borderColor: "#ced4da",
      },
      "& .MuiIconButton-root": {
        padding: "6px",
      },
      "& .MuiOutlinedInput-input": {
        height: "auto !important",
        border: 0,
        fontSize: 12,
        padding: "8px 0 8px 10px",
        fontFamily: [
          "-apple-system",
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
        width: 100,
      },
      "& .MuiInputAdornment-positionEnd": {
        marginLeft: "0 !important",
      },
      "& .MuiIconButton-label": {
        justifyContent: "center",
      },
    },
  })

  const classes = useStyles()

  const handleCancel = () => {
    dispatch.calendarEvents.getEvents()
    hideSplits()
  }

  const { handleSubmit } = useForm<FormFieldsType>()

  const submitSplitShifts = () => {
    const splitShiftsFiltered = newSplitShifts.filter(
      (splitShift) => !splitShift.disabled
    )

    if (splitShiftsFiltered && job) {
      const splitShiftValidation = validateSplitShifts(
        splitShiftsFiltered,
        job,
        new Date(`${edate}T00:00:00`)
      )
      if (splitShiftValidation) {
        Dialog.warn({
          title: "Error",
          message: splitShiftValidation,
          style: { zIndex: 10000 },
        })
      } else {
        onChange({
          type: "split_shift",
          data: splitShiftsFiltered.map((splitShift) => {
            return {
              ...splitShift,
              edate,
              jobid,
            }
          }),
          changed: true,
        })
        hideSplits()
      }
    }
  }

  return (
    <Form
      className={css.splitShiftForm}
      onSubmit={handleSubmit(submitSplitShifts)}
    >
      {newSplitShifts.map((splitShift, index) => (
        <div className="row" key={index}>
          <Form.Group
            as={Col}
            controlId={`providerSelect-${index}`}
            className="col-md-4"
          >
            <Form.Label className={css.labelPopup}>Provider(s)</Form.Label>
            <ProvidersCustomSelectComponent
              jobid={String(job?.jobid)}
              edate={edate || ""}
              onChange={(providerid) =>
                changeSplitShiftProvider(index, Number(providerid))
              }
              defaultProviderId={String(splitShift.providerid)}
              disabled={splitShift.disabled}
            />
          </Form.Group>

          <Form.Group
            as={Col}
            controlId={`shiftTimes-${index}`}
            className="col-md-4"
          >
            <Fragment>
              <Form.Label className={css.labelPopup}>Shift Times</Form.Label>
              <div className={css.pickerTimeContainer}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardTimePicker
                    onChange={(date) =>
                      changeSplitShiftTimes(
                        index,
                        formatTime(date),
                        splitShift.endtime
                      )
                    }
                    value={
                      splitShift.starttime
                        ? formatDate(
                            parseDate(splitShift.starttime, (f) => f.timeOnly),
                            "yyyy-MM-dd HH:mm:ss"
                          )
                        : null
                    }
                    inputVariant="outlined"
                    className={classes.root}
                    variant="inline"
                    mask="__:__ _M"
                    keyboardIcon={<AccessTimeIcon />}
                    disabled={splitShift.disabled}
                  />
                  <span className={css.timeSeparator}>to</span>
                  <KeyboardTimePicker
                    onChange={(date) =>
                      changeSplitShiftTimes(
                        index,
                        splitShift.starttime,
                        formatTime(date)
                      )
                    }
                    value={
                      splitShift.endtime
                        ? formatDate(
                            parseDate(splitShift.endtime, (f) => f.timeOnly),
                            "yyyy-MM-dd HH:mm:ss"
                          )
                        : null
                    }
                    inputVariant="outlined"
                    className={classes.root}
                    variant="inline"
                    mask="__:__ _M"
                    keyboardIcon={<AccessTimeIcon />}
                    disabled={splitShift.disabled}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </Fragment>
          </Form.Group>
          <Form.Group
            as={Col}
            controlId={`tallys-${index}`}
            className="col-md-3 offset-md-1"
          >
            <Form.Label className={css.labelTimes}>Tallys</Form.Label>
            <div style={{ display: "flex" }}>
              <Select
                className={css.select}
                onChange={(val) => changeSplitShiftTallyCredit(index, val)}
                disabled={splitShift.disabled}
                value={splitShift.tally_credit}
                clearable={false}
                options={[0, 0.25, 0.5, 0.75, 1].map((val) => {
                  return {
                    id: val,
                    name: val,
                  }
                })}
              />
              <IconButton
                component="span"
                onClick={() => clearSplitShiftRow(index)}
                className={css.clearButton}
                style={{
                  visibility: splitShift.disabled ? "hidden" : "visible",
                }}
              >
                <ClearIcon fontSize="small" style={{ color: "#A2B0BC" }} />
              </IconButton>
            </div>
          </Form.Group>
        </div>
      ))}
      <div className="row">
        <div className="col-md-3 offset-md-9">
          <Button
            variant="outline-secondary"
            className={cx("mr-5", css.deleteButton)}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button variant="primary" className={css.submitButton} type="submit">
            Apply Splits
          </Button>
        </div>
      </div>
    </Form>
  )
}
